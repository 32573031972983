import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { useAuth } from './contexts/AuthContext';
import Header from './components/Header';
import Home from './components/Home';
import CarListings from './components/CarListings';
import CarDetail from './components/CarDetail';
import SellMyCar from './components/SellMyCar';
import SignUp from './components/SignUp';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import PasswordReset from './components/PasswordReset';
import ProfilePage from './components/ProfilePage';
import Profile from './components/Profile';
import EditListing from './components/EditListing';
import RequestCar from './components/RequestCars';
import BuyerRequests from './components/BuyerRequests';
import './App.css';
import SavedCars from './components/SavedCars';
import SellerProfile from './components/SellerProfile';
import ChatInterface from './components/ChatInterface';
import VerifyEmail from './components/VerifyEmail';
import ConfirmPasswordReset from './components/ConfirmPasswordReset';
import GoogleCallback from './components/GoogleCallback';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0052FF',
    },
  },
  background: {
    default: '#F1EFE5',
  },
  typography: {
    fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
    h1: {
      fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
    },
    h2: {
      fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
    },
    h3: {
      fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
    },
    h4: {
      fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
    },
    h5: {
      fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
    },
    h6: {
      fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
    },
    body1: {
      fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
    },
    body2: {
      fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          font-family: "Rubik", "Noto Sans Mongolian", sans-serif;
        }
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: '"Rubik", "Noto Sans Mongolian", sans-serif',
        },
      },
    },
  },
});


function App() {
  const { setToken } = useAuth();  // Get setToken from AuthContext

  useEffect(() => {
    // Check for token in URL params when app loads
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    
    if (token) {
      // Store the token
      localStorage.setItem('token', token);
      // Remove token from URL
      window.history.replaceState({}, document.title, window.location.pathname);
      // Update auth context
      setToken(token);
    }
  }, [setToken]);  // Add setToken as dependency

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <Header />
        <Routes>
          <Route path="/saved" element={<PrivateRoute><SavedCars /></PrivateRoute>} />
          <Route path="/" element={<Home />} />
          <Route path="/listings/:pageNum?" element={<CarListings />} />
          <Route path="/car/:id" element={<CarDetail />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/seller/:sellerId" element={<SellerProfile />} />
          <Route path="/verify-email/:token" element={<VerifyEmail />} />
          <Route path="/request-car" element={<PrivateRoute><RequestCar /></PrivateRoute>} />
          <Route path="/buyer-requests" element={<BuyerRequests />} />
          <Route path="/account" element={<ProfilePage />} />
          <Route path="/reset-password" element={<PasswordReset />} />
          <Route path="/reset-password/:token" element={<ConfirmPasswordReset />} />
          <Route path="/edit-listing/:id" element={<PrivateRoute><EditListing /></PrivateRoute>} />
          <Route path="/sell-my-car" element={<PrivateRoute><SellMyCar /></PrivateRoute>} />
          <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path="/chat" element={<PrivateRoute><ChatInterface /></PrivateRoute>} />
          <Route path="/auth/google/callback" element={<GoogleCallback />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default App;