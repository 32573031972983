// src/components/SellMyCar.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  TextField, Button, Typography, Box, Select, MenuItem, FormControl, 
  InputLabel, Switch, FormControlLabel, Stepper, Step, StepLabel, 
  Paper, IconButton, Slider, StepButton, CircularProgress, Dialog,
  DialogActions, DialogContent, DialogContentText, DialogTitle, 
  InputAdornment, Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import DeleteIcon from '@mui/icons-material/Delete';
import { carData, carMakes } from '../data/carData';
import { useAuth } from '../contexts/AuthContext';
import { 
  saveCarListing, getUserInfo, initiateKhanBankPayment, 
  checkKhanBankPaymentStatus, checkPaymentStatus
} from '../utils/db';
import PurchaseSlots from './PurchaseSlots';
import { api } from '../contexts/AuthContext';
import { isMobileDevice } from '../utils/deviceDetection'; // Import device detection

const primaryBlue = '#0052FF';

const theme = createTheme({
  typography: {
    fontFamily: 'Rubik Arial, sans-serif',
  },
  palette: {
    primary: {
      main: primaryBlue,
    },
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'Rubik, Arial, sans-serif',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontFamily: 'Rubik, Arial, sans-serif',
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            color: primaryBlue,
          },
          '&.Mui-completed': {
            color: primaryBlue,
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          '&.Mui-active': {
            color: primaryBlue,
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: primaryBlue,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&.Mui-checked': {
            color: primaryBlue,
          },
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: primaryBlue,
          },
        },
      },
    },
  },
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: '15px',
  boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
}));

const ImagePreview = styled('img')({
  width: 100,
  height: 100,
  objectFit: 'cover',
  borderRadius: '10px',
  margin: '5px',
});

const SellMyCar = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [activeStep, setActiveStep] = useState(0);
  const [availableSlots, setAvailableSlots] = useState(0);
  const [showPurchaseSlots, setShowPurchaseSlots] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(null);
  const [showQRCodeDialog, setShowQRCodeDialog] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [shortUrl, setShortUrl] = useState('');
  const [invoiceId, setInvoiceId] = useState('');
  const [paymentId, setPaymentId] = useState('');
  const [qrCodeData, setQrCodeData] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('qpay'); // Payment method state
  const [khanBankUrl, setKhanBankUrl] = useState(''); // Khan Bank URL state
  const [price, setPrice] = useState('');
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [isPolling, setIsPolling] = useState(false);
  const [carDetails, setCarDetails] = useState({
    make: '',
    model: '',
    yearMade: '',
    yearImported: '',
    odometer: '',
    gearbox: '',
    engine: 0,
    seats: '',
    fuelType: '',
    steering: '',
    exteriorColor: '',
    interiorColor: '',
    mpgCity: '',
    mpgHighway: '',
    vin: '',
    drivetrain: '',
    tradeIn: 'no',
    sellerLocation: currentUser?.location || '',
    sellerPhone: currentUser?.phoneNumber || '',
    price: '',
    description: '',
    images: [],
    willBeShipped: false,
    startingPrice: '',
    endDate: ''
  });
  const [selectedImages, setSelectedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const gearboxOptions = ['Автомат', 'Механик', 'Хагас Автомат'];
  const fuelTypeOptions = ['Бензин', 'Диезел', 'Хайбрид', 'Цахилгаан', 'Газ', 'Устөрөгч'];
  const steeringOptions = ['Зөв', 'Буруу'];
  const drivetrainOptions = ['Урд 2 Дугуй', 'Хойд 2 Дугуй', '4 Дугуй'];
  const shippingCountries = ['Америк', 'Япон', 'Cолонгос', 'Орос', 'Хятад', 'Германь', 'Англи', 'Бусад'];
  const localLocations = ['Улаанбаатар','Архангай', 'Баян-Өлгий', 'Баянхонгор', 'Булган', 'Говь-Алтай', 'Говьсүмбэр', 'Дархан-Уул', 'Дорноговь', 'Дорнод', 'Дундговь', 'Завхан', 'Орхон', 'Өвөрхангай', 'Өмнөговь', 'Сүхбаатар', 'Сэлэнгэ', 'Төв', 'Увс', 'Ховд', 'Хөвсгөл', 'Хэнтий'];
  const colorOptions = ['Хар', 'Цагаан', 'Мөнгөлөг', 'Саарал', 'Улаан', 'Цэнхэр', 'Ногоон', 'Шар', 'Бор', 'Бусад'];

  const steps = ['Ерөнхий Мэдээлэл', 'Нэмэлт Мэдээлэл', 'Үнэ', 'Зураг & Тайлбар'];

  const handlePriceChange = (values) => {
    const { value } = values;
    setPrice(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCarDetails(prev => {
      const newDetails = { ...prev, [name]: value };
      if (name === 'make') {
        newDetails.model = '';
      }
      if (name === 'fuelType' && value === 'Цахилгаан') {
        newDetails.engine = 0;
        newDetails.mpgCity = '';
        newDetails.mpgHighway = '';
      }
      return newDetails;
    });
  };

  const handleSliderChange = (name) => (event, newValue) => {
    setCarDetails(prev => ({ ...prev, [name]: newValue }));
  };

  const handleWillBeShippedToggle = (event) => {
    setCarDetails(prev => ({ ...prev, willBeShipped: event.target.checked }));
  };

  const MAX_FILE_SIZE = 30 * 1024 * 1024; // 30MB in bytes

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    let totalSize = selectedImages.reduce((acc, file) => acc + file.size, 0);
    
    for (let file of files) {
      totalSize += file.size;
    }

    if (totalSize > MAX_FILE_SIZE) {
      setFileSizeError("Уучлаарай, таны оруулсан зургуудын нийт хэмжээ 30МБ-аас хэтэрсэн байна.");
      return;
    }

    setFileSizeError(null);
    setSelectedImages(prevImages => [...prevImages, ...files]);
  };

  const removeImage = (index) => {
    setSelectedImages(prevImages => prevImages.filter((_, i) => i !== index));
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fetchUserInfo = async () => {
    try {
      const userInfo = await getUserInfo();
      setAvailableSlots(userInfo.available_listing_slots);
    } catch (error) {
      console.error('Error fetching user info:', error);
      setError('Failed to fetch user information. Please try again.');
    }
  };

  const handleInitiatePayment = async (url, shortUrl, paymentId, invoiceId, method) => {
    setPaymentMethod(method);
    if (method === 'qpay') {
      setQrCodeUrl(url);
      setShortUrl(shortUrl);
    } else if (method === 'khanbank') {
      setKhanBankUrl(url);
    }
    setPaymentId(paymentId);
    setInvoiceId(invoiceId);
    setQrCodeData(url);
    setShowQRCodeDialog(true);
    setShowPurchaseSlots(false); // Close the PurchaseSlots dialog
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (availableSlots <= 0) {
      setShowPurchaseSlots(true);
      return;
    }
    setIsLoading(true);
    setError(null);
  
    const formData = new FormData();
  
    Object.keys(carDetails).forEach(key => {
      if (key === 'price') {
        formData.append(key, carDetails[key].replace(/,/g, ''));
      } else if (key !== 'images') {
        formData.append(key, carDetails[key]);
      }
    });
  
    selectedImages.forEach((image, index) => {
      formData.append(`images`, image);
    });
  
    try {
      const response = await saveCarListing(formData);
      console.log('Listing saved successfully:', response);
      setIsLoading(false);
      navigate('/listings');
    } catch (error) {
      console.error('Error saving car listing:', error);
      setError('Failed to save listing. Please try again.');
      setIsLoading(false);
    }
  };

  const handleClosePurchaseSlots = () => {
    setShowPurchaseSlots(false);
  };

  const handleSuccessfulPurchase = async () => {
    try {
      const userInfo = await getUserInfo();
      setAvailableSlots(userInfo.available_listing_slots);
      // Optionally, you can clear payment-related states here
      setShowQRCodeDialog(false);
      setPaymentStatus(null);
    } catch (error) {
      console.error('Error fetching user info:', error);
      setError('Failed to update slot information. Please try again.');
    }
  };

  const handleCheckPaymentStatus = async () => {
    if (!paymentId) {
      setError('No payment ID available. Please try again.');
      return;
    }
  
    setIsPolling(true);
    setError(null);
  
    try {
      let response;
      let data;
  
      if (paymentMethod === 'qpay') {
        // Check payment status for QPay
        response = await api.get(`/check-payment-status/?payment_id=${paymentId}`);
        data = response.data;
  
        if (data.status === 'PAID' || data.slot_purchase_status === 'completed') {
          setPaymentStatus('completed');
          setAvailableSlots(data.available_slots);
          await handleSuccessfulPurchase();
        } else if (data.status === 'FAILED' || data.slot_purchase_status === 'failed') {
          setPaymentStatus('failed');
          setError('Payment failed. Please try again or choose a different payment method.');
        } else {
          setPaymentStatus('pending');
        }
      } else if (paymentMethod === 'khanbank') {
        // Check payment status for Khan Bank
        response = await api.get(`/khanbank/check-payment-status/?payment_id=${paymentId}`);
        data = response.data;
  
        if (data.status === 2 || data.slot_purchase_status === 'completed') {
          setPaymentStatus('completed');
          setAvailableSlots(data.available_slots);
          await handleSuccessfulPurchase();
        } else if ([1, 3, 4, 5, 6].includes(data.status) || data.slot_purchase_status === 'failed') {
          setPaymentStatus('failed');
          setError(`Payment failed: ${data.error_message || 'Unknown error'}. Please try again or choose a different payment method.`);
        } else {
          setPaymentStatus('pending');
        }
  
        console.log('Khan Bank payment details:', {
          status: data.status,
          errorCode: data.error_code,
          errorMessage: data.error_message,
          slotPurchaseStatus: data.slot_purchase_status
        });
      } else {
        setError('Unsupported payment method.');
        setIsPolling(false);
        return;
      }
  
      setIsPolling(false);
    } catch (error) {
      console.error('Error checking payment status:', error);
      setError('Failed to check payment status. Please try again.');
      setIsPolling(false);
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const isStepComplete = (step) => {
    switch (step) {
      case 0:
        return carDetails.make && carDetails.model && carDetails.yearMade && carDetails.yearImported && carDetails.fuelType;
      case 1:
        if (carDetails.fuelType === 'Цахилгаан') {
          return carDetails.odometer && carDetails.gearbox && carDetails.seats && 
                 carDetails.steering && carDetails.drivetrain && carDetails.exteriorColor && 
                 carDetails.interiorColor;
        } else {
          return carDetails.odometer && carDetails.gearbox && carDetails.seats && 
                 carDetails.steering && carDetails.drivetrain && carDetails.exteriorColor && 
                 carDetails.interiorColor && carDetails.engine > 0 && 
                 carDetails.mpgCity && carDetails.mpgHighway;
        }
      case 2:
        return carDetails.price;
      case 3:
        return selectedImages.length > 0 && carDetails.sellerPhone && carDetails.sellerLocation;
      default:
        return false;
    }
  };

  const StyledFormControl = styled(FormControl)({
    '& .MuiInputBase-root': {
      fontFamily: 'Poppins, Arial, sans-serif',
    },
    '& .MuiFormLabel-root': {
      fontFamily: 'Poppins, Arial, sans-serif',
    },
  });

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <StyledFormControl fullWidth margin="normal">
              <InputLabel id="make-label">Үйлдвэрлэгч</InputLabel>
              <Select
                labelId="make-label"
                name="make"
                value={carDetails.make}
                label="Make"
                onChange={handleChange}
                required
              >
                {carMakes.map((make, index) => (
                  <MenuItem key={index} value={make}>{make}</MenuItem>
                ))}
              </Select>
            </StyledFormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel id="model-label">Загвар</InputLabel>
              <Select
                labelId="model-label"
                name="model"
                value={carDetails.model}
                label="Model"
                onChange={handleChange}
                required
                disabled={!carDetails.make}
              >
                {carDetails.make && carData[carDetails.make].map((model, index) => (
                  <MenuItem key={index} value={model}>{model}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField fullWidth margin="normal" name="yearMade" label="Үйлдвэрлэсэн Он" type="number" value={carDetails.yearMade} onChange={handleChange} required />
            <TextField fullWidth margin="normal" name="yearImported" label="Орж Ирсэн Он" type="number" value={carDetails.yearImported} onChange={handleChange} required />
            <StyledFormControl fullWidth margin="normal">
              <InputLabel id="fuel-type-label">Шатахуун</InputLabel>
              <Select
                labelId="fuel-type-label"
                name="fuelType"
                value={carDetails.fuelType}
                label="Fuel-Type"
                onChange={handleChange}
                required
              >
                {fuelTypeOptions.map((option) => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </>
        );
      case 1:
        return (
          <>
            <TextField 
              fullWidth 
              margin="normal" 
              name="odometer" 
              label="Гүйлт" 
              type="number" 
              value={carDetails.odometer} 
              onChange={handleChange} 
              required 
            />
            {carDetails.fuelType !== 'Цахилгаан' && (
              <>
                <Typography gutterBottom>Хөдөлгүүрийн Багтаамж</Typography>
                <Slider
                  value={carDetails.engine}
                  onChange={handleSliderChange('engine')}
                  valueLabelDisplay="auto"
                  min={0}
                  max={10}
                  step={0.1}
                />
                <Typography gutterBottom>{carDetails.engine.toFixed(1)}L</Typography>
              </>
            )}
            <StyledFormControl fullWidth margin="normal">
              <InputLabel id="gearbox-label">Хурдны Хайрцаг</InputLabel>
              <Select
                labelId="gearbox-label"
                name="gearbox"
                value={carDetails.gearbox}
                label="Gearbox"
                onChange={handleChange}
                required
              >
                {gearboxOptions.map((option) => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </StyledFormControl>
            <TextField fullWidth margin="normal" name="seats" label="Суудлын Тоо" type="number" value={carDetails.seats} onChange={handleChange} required />
            <StyledFormControl fullWidth margin="normal">
              <InputLabel id="exterior-color-label">Гадна өнгө</InputLabel>
              <Select
                labelId="exterior-color-label"
                name="exteriorColor"
                value={carDetails.exteriorColor}
                label="Exterior Color"
                onChange={handleChange}
                required
              >
                {colorOptions.map((option) => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </StyledFormControl>
            <StyledFormControl fullWidth margin="normal">
              <InputLabel id="interior-color-label">Дотор өнгө</InputLabel>
              <Select
                labelId="interior-color-label"
                name="interiorColor"
                value={carDetails.interiorColor}
                label="Interior Color"
                onChange={handleChange}
                required
              >
                {colorOptions.map((option) => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </StyledFormControl>
            {carDetails.fuelType !== 'Цахилгаан' && (
              <>
                <Typography variant="subtitle1" gutterBottom>Шатахуун зарцуулалт (л/100км)</Typography>
                <Box display="flex" justifyContent="space-between">
                  <TextField
                    style={{ width: '48%' }}
                    margin="normal"
                    name="mpgCity"
                    label="Хот дотор"
                    type="number"
                    value={carDetails.mpgCity}
                    onChange={handleChange}
                    inputProps={{ step: 0.1, min: 0 }}
                  />
                  <TextField
                    style={{ width: '48%' }}
                    margin="normal"
                    name="mpgHighway"
                    label="Хот гадна"
                    type="number"
                    value={carDetails.mpgHighway}
                    onChange={handleChange}
                    inputProps={{ step: 0.1, min: 0 }}
                  />
                </Box>
              </>
            )}
            <TextField
              fullWidth
              margin="normal"
              name="vin"
              label="Арлын дугаар (Заавал биш)"
              value={carDetails.vin}
              onChange={handleChange}
            />
            <StyledFormControl fullWidth margin="normal">
              <InputLabel id="steering-label">Хүрд</InputLabel>
              <Select
                labelId="steering-label"
                name="steering"
                value={carDetails.steering}
                label="Steering"
                onChange={handleChange}
                required
              >
                {steeringOptions.map((option) => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </StyledFormControl>
            <StyledFormControl fullWidth margin="normal">
              <InputLabel id="drivetrain-label">Хөтлөгч</InputLabel>
              <Select
                labelId="drivetrain-label"
                name="drivetrain"
                value={carDetails.drivetrain}
                label="Drivetrain"
                onChange={handleChange}
                required
              >
                {drivetrainOptions.map((option) => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </>
        );
      case 2:
        return (
          <>
            <TextField
              fullWidth
              margin="normal"
              name="price"
              label="Үнэ"
              value={carDetails.price}
              onChange={(e) => {
                const value = e.target.value.replace(/[^\d]/g, '');
                if (value === '' || (parseInt(value, 10) <= 9999999999)) {
                  setCarDetails(prev => ({
                    ...prev,
                    price: value === '' ? '' : parseInt(value, 10).toLocaleString()
                  }));
                }
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">₮</InputAdornment>,
              }}
            />
            <FormControlLabel
              control={<Switch checked={carDetails.willBeShipped} onChange={handleWillBeShippedToggle} />}
              label="Өөр Улсаас Ачигдах"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel id="trade-in-label">Машин Оролцуулах</InputLabel>
              <Select
                labelId="trade-in-label"
                name="tradeIn"
                value={carDetails.tradeIn}
                label="Allow Trade-In"
                onChange={handleChange}
                required
              >
                <MenuItem value="yes">Тийм</MenuItem>
                <MenuItem value="no">Үгүй</MenuItem>
              </Select>
            </FormControl>
          </>
        );
      case 3:
        return (
          <>
            <Box className="image-upload-container" sx={{ mt: 2, mb: 2 }}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                multiple
                type="file"
                onChange={handleImageUpload}
              />
              <label htmlFor="raised-button-file">
                <Button
                  variant="outlined"
                  component="span"
                  fullWidth
                  startIcon={<AddPhotoAlternateIcon />}
                >
                  Зураг Оруулах
                </Button>
              </label>
            </Box>
            {fileSizeError && (
              <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                {fileSizeError}
              </Alert>
            )}
            {selectedImages.length > 0 && (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                {selectedImages.map((image, index) => (
                  <Box key={index} sx={{ position: 'relative' }}>
                    <ImagePreview src={URL.createObjectURL(image)} alt={`Preview ${index + 1}`} />
                    <IconButton
                      size="small"
                      onClick={() => removeImage(index)}
                      sx={{ position: 'absolute', top: 0, right: 0, bgcolor: 'rgba(255,255,255,0.7)' }}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            )}
            <TextField fullWidth margin="normal" name="description" label="Нэмэлт Тайлбар" multiline rows={4} value={carDetails.description} onChange={handleChange}/>
            <TextField 
              fullWidth 
              margin="normal" 
              name="sellerPhone" 
              label="Утасны Дугаар" 
              value={carDetails.sellerPhone}
              onChange={handleChange} 
              required 
            />
            <StyledFormControl fullWidth margin="normal">
              <InputLabel id="location-label">Байршил</InputLabel>
              <Select
                labelId="location-label"
                name="sellerLocation"
                value={carDetails.sellerLocation}
                label="Your Location"
                onChange={handleChange}
                required
              >
                {carDetails.willBeShipped
                  ? shippingCountries.map((country) => (
                      <MenuItem key={country} value={country}>{country}</MenuItem>
                    ))
                  : localLocations.map((location) => (
                      <MenuItem key={location} value={location.toString()}>{location}</MenuItem>
                    ))
                }
              </Select>
            </StyledFormControl>
          </>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className="sell-my-car" sx={{ maxWidth: 600, margin: '0 auto', padding: 3 }}>
        <Stepper nonLinear activeStep={activeStep} sx={{ marginBottom: 3 }}>
          {steps.map((label, index) => (
            <Step key={label} completed={isStepComplete(index)}>
              <StepButton onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <form onSubmit={handleSubmit}>
          <StyledPaper>
            {getStepContent(activeStep)}
          </StyledPaper>
          {error && (
            <Typography color="error" sx={{ mt: 2 }}>
              {error}
            </Typography>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button
              disabled={activeStep === 0 || isLoading}
              onClick={handleBack}
              variant="outlined"
            >
              Буцах
            </Button>
            <Button
              variant="contained"
              color="primary"
              type={activeStep === steps.length - 1 ? 'button' : 'button'}
              onClick={
                activeStep === steps.length - 1
                  ? availableSlots > 0
                    ? handleSubmit
                    : () => setShowPurchaseSlots(true)
                  : handleNext
              }
              disabled={!isStepComplete(activeStep) || isLoading}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : activeStep === steps.length - 1 ? (
                availableSlots > 0 ? 'Зар Нийтлэх' : 'Эрх Худалдаж Авах'
              ) : (
                'Дараагийх'
              )}
            </Button>
          </Box>
        </form>

        {/* PurchaseSlots Dialog */}
        <PurchaseSlots 
          open={showPurchaseSlots} 
          onClose={handleClosePurchaseSlots} 
          onPurchase={handleSuccessfulPurchase} 
          onInitiatePayment={handleInitiatePayment} 
          paymentMethod={paymentMethod} // Pass payment method
          setPaymentMethod={setPaymentMethod} // Pass setPaymentMethod
        />

        {/* Payment Status Dialog */}
        <Dialog 
          open={showQRCodeDialog} 
          onClose={() => {
            setShowQRCodeDialog(false);
            setPaymentStatus(null);
          }}
        >
          <DialogTitle>Төлбөр хийх</DialogTitle>
          <DialogContent>
            {paymentMethod === 'qpay' ? (
              <>
                <DialogContentText>
                  Та QR кодыг уншуулж эсвэл богино холбоосыг ашиглан төлбөрөө хийж болно.
                </DialogContentText>
                {!isMobileDevice() && qrCodeData && (
                  <Box sx={{ textAlign: 'center', mt: 2 }}>
                    <img src={`data:image/png;base64,${qrCodeData}`} alt="QR Code" style={{ width: '200px', height: '200px' }} />
                  </Box>
                )}
                {shortUrl && (
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    Богино холбоос: <a href={shortUrl} target="_blank" rel="noopener noreferrer">{shortUrl}</a>
                  </Typography>
                )}
              </>
            ) : paymentMethod === 'khanbank' ? (
              <>
                <DialogContentText>
                  Төлбөр хийхийн тулд дараах холбоос дээр дарна уу.
                </DialogContentText>
                {khanBankUrl && (
                  <Box sx={{ textAlign: 'center', mt: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      href={khanBankUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Төлбөрийн хуудсыг нээх
                    </Button>
                  </Box>
                )}
              </>
            ) : null}
            {isPolling && (
              <Typography variant="body2" sx={{ mt: 2 }}>
                Төлбөрийн статусыг шалгаж байна...
              </Typography>
            )}
            {paymentStatus === 'completed' && (
              <Alert severity="success" sx={{ mt: 2 }}>
                Төлбөр амжилттай хийгдлээ! Таны зарын эрх нэмэгдлээ.
              </Alert>
            )}
            {paymentStatus === 'failed' && (
              <Alert severity="error" sx={{ mt: 2 }}>
                Төлбөр амжилтгүй боллоо. Дахин оролдоно уу.
              </Alert>
            )}
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'space-between' }}>
            <Button 
              onClick={() => {
                setShowQRCodeDialog(false);
                setPaymentStatus(null);
              }} 
              variant="outlined"
            >
              Хаах
            </Button>
            <Button 
              onClick={handleCheckPaymentStatus} 
              color="primary" 
              disabled={isPolling || paymentStatus === 'completed'}
              variant="contained"
            >
              {paymentMethod === 'qpay' ? 'Төлбөрийн Статусыг шалгах' : 'Статус шалгах'}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default SellMyCar;
